/*
# f-hero

Hero. Has a full width [of the site content] background image with text content on top.
Image inserted as html img tag and via js set as css background-image. The
image is initial hidden but is reveal in a translateY/opacity movement, when
the js has run and the image is loaded.

Known issue: With some image dimensions and browser window dimensions the image is too short.
E.i. the image doesn't reach the top of the container and the black background is visible.

<pre>
BLOCK f-hero
	MODIFIER f-hero_big
	ELEMENT f-hero__wrapper
		ELEMENT f-hero__heading
		MODIFIER f-hero__heading_typewriter
		ELEMENT f-hero__text
		ELEMENT f-hero__heading-2
</pre>

## With grid
```
<div class="f-hero">
	<img src="foo/bar.png">
	<div class="f-hero__wrapper">
		<div class="f-row f-row_6">
			<div class="f-row__cell">
				<div class="f-hero__heading">
					<h1><span>Project:</span></h1><br>
					<h1>Rebuild Kobanê</h1>
				</div>
			</div>
			<div class="f-row__cell-2 f-row__cell-mobile-5">
				<p class="f-hero__text">
						<span>We have always dealt with oppression, but we do not only fight</span>
						<span>against ISIS - we fight for freedom. We are the Kobanê</span>
						<span>Reconstruction Board, and this is a global call to action, to stand</span>
						<span>with us and rebuild our city.</span>
				</p>
			</div>
		</div>
	</div><!-- /f-hero__wrapper -->
</div>
```
*/

@import "../variables/variables.css";

.f-hero {
  height: 31rem;
  width: 100%;
  margin-bottom: 3rem;
  padding-top: 4rem;
  /*TODO: set background-image via js */
  /* background-image: url(https://firefund-assets.s3.amazonaws.com/projecthero.png); */
  background-color: black;
  /* Sepian.jpg is preloaded in frontpage.ejs */
  background-image: url(https://firefund-assets.s3.amazonaws.com/Sepian-mobile.jpg);
  background-repeat: no-repeat;
  background-position: center bottom;
  /* cut sides until media query limit */
  background-size: cover;
}

@media screen and (--viewport-desktop) {
  .f-hero {
    background-image: url(https://firefund-assets.s3.amazonaws.com/Sepian.jpg);
  }
}

.f-hero.f-hero_big {
  height: 66rem;
}

.f-hero_video {
  max-height: 74rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-bottom: 3rem;
}

.f-hero__default {
  height: 35rem;
}

.f-hero__rentstrike {
  height: 35rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-rentstrike3.png);
}

.f-hero__bringshadihome {
  height: 35rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-bringshadihomehero.png);
}

.f-hero__rebuildkobane {
  height: 35rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/headerkobane.png);
}

.f-hero__cutiepoc {
  height: 35rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-cutiepoc.jpg);
}

@media screen and (--viewport-mobile) {
  .f-hero__cutiepoc {
    height: 43rem;
  }
}

.f-hero__byensradio {
  height: 35rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-byensbanner3.png);
}

.f-hero__brigade {
  height: 35rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-brigade19juli.jpg);
}

.f-hero__hamburg {
  height: 45rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-hamburglegalteam.jpg);
}

.f-hero__wedey {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-wedey_cover.png);
  background-color: white;
}

.f-hero__ism {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-ismcover.png);
  background-color: white;
}

.f-hero__solidarityuganda {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-solidarityuganda.jpg);
  background-color: white;
}

.f-hero__skopje {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-skopjecover.png);
  background-color: white;
}

.f-hero__marronage {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-marronage.jpg);
  background-color: white;
}

.f-hero__gabungan {
  height: 54rem;
  background-color: hsl(1, 78%, 51%);
  background-image: url(/assets/image/h-gabungan.png);
}

.f-hero__todoporhacer {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-todoporhacercover.jpg);
  background-color: white;
}

.f-hero__ukraine {
  height: 35rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-ukraine.jpeg);
}

.f-hero__hungerstrike {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-hungerstrike.jpg);
}

.f-hero__lilavilla {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-lilabanner.png);
}

.f-hero__bookfair {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-bookfair.jpg);
}

.f-hero__prisonsoli {
  height: 80rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-prisonsoli.jpg);
}

.f-hero__asykamo {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-asykamo.jpg);
}

.f-hero__malmo {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-malmo.jpg);
}

.f-hero__disorientationguide {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-disorientationguide.png);
}

.f-hero__konfront {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-konfront.png);
}

.f-hero__endegelande {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-endegelande.jpg);
}

.f-hero__parkonavarinou {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-parkonavarinou.jpg);
}

.f-hero__cos {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-cos.jpeg);
  background-position: center top;
}

.f-hero__rupression {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-rupression.png);
}

.f-hero__rupression-mobile {
  height: 64rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-rupressionbanner_mobile.png);
  background-size: contain;
}

.f-hero__ric {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-ric.jpg);
  background-position: left center;
}

.f-hero__elmcityram {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-elmcityram.jpg);
}

.f-hero__abc {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-abc.jpg);
}

.f-hero__tierragrata {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/tierragratabanner.png);
}

.f-hero__malmo2 {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-malmo2.jpg);
}

.f-hero__sb19 {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-stockholm.jpg);
}

.f-hero__rsweekender {
  height: 30rem;
  background-color: hsl(306, 100%, 81%);
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-rsweekender.png);
  background-size: contain;
  background-position: center;
}

.f-hero__rsweekender-mobile {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/t-rsweekender.jpg);
}

.f-hero__freethesoil {
  height: 60rem !important;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-freethesoil.jpg);
}

.f-hero__freethesoil-mobile {
  background-image: url(https://firefund-assets.s3.amazonaws.com/t-freethesoil.jpg);
}

.f-hero__egs {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-egs.jpg);
}

.f-hero__occupyicela {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-occupyicela.png);
}

.f-hero__occupyicela-mobile {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/t-occupyicela.jpg);
}

.f-hero__bookfaircph {
  height: 45rem;
  background-color: white;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-bookfaircph.jpg);
  background-size: contain !important;
  background-position: 50%;
}

.f-hero__bookfaircph-mobile {
  background-image: url(https://firefund-assets.s3.amazonaws.com/t-bookfaircph.jpg);
  background-size: contain;
}

.f-hero__folkmotfossilgas {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-folkmotfossilgas.png);
}

.f-hero__folkmotfossilgas-mobile {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/t-folkmotfossilgas.jpg);
}

.f-hero__papa {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-png.jpg);
}

.f-hero__rojava {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-rojava.jpg);
}

.f-hero__hevaltommy {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-hevaltommy.jpg);
}

.f-hero__uganda {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-solidarityuganda.jpg);
}

.f-hero__frostfestival {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-frostfestival.jpg);
}

.f-hero__persecutedantifa {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-persecutedantifa.jpg);
}

.f-hero__fourfouronethree {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-fourfouronethree.jpg);
}

.f-hero__afalegaldefensefund {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-afalegaldefensefund.jpg);
}

.f-hero__7hari7malam {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-7hari7malam.png);
}

.f-hero__7hari7malam-mobile {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/t-7hari7malam.jpg);
}

.f-hero__gothenburgafa {
  height: 90rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-gothenburgafa.jpg);
}

.f-hero__gothenburgafa-mobile {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/t-gothenburgafa.jpg);
}

.f-hero__campesinos {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-campesinos.jpg);
}

.f-hero__isf {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-isf.jpg);
}

.f-hero__covid61 {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-covid61.jpg);
}

.f-hero__idrima {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-idrima.jpg);
}

.f-hero__bail4comrades {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-bail4comrades.jpg);
}

.f-hero__jktv {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-jktv.jpg);
}

.f-hero__landback {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-landback.jpg);
}

.f-hero__hidden {
  height: 60rem;
  /*background-image: url(https://firefund-assets.s3.amazonaws.com/h-hidden.jpg);*/
  background-image: none;
  background-color: white;
}

.f-hero__solidarity2020 {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-solidarity2020.jpg);
}

.f-hero__danskanarki {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-danskanarki.jpg);
}

.f-hero__stopbzdurom {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-stopbzdurom.jpg);
}

.f-hero__stopbzdurom-mobile {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/t-stopbzdurom.png);
}

.f-hero__witchcamps {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-witchcamps.jpg);
}

.f-hero__stoa60 {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-stoa60_banner.jpg);
}

.f-hero__jetpack {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-jetpack.jpg);
}

.f-hero__defendharvest {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-defendharvest.jpg);
}

.f-hero__cutiebipocfest {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-cutiebipocfest.jpg);
}

.f-hero__belarus {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-belarus.jpg);
}

.f-hero__osa {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-osa.jpg);
}

.f-hero__underathens {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-underathens.jpg);
}

.f-hero__sgp {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-sgp.jpg);
}

.f-hero__solidarfund {
  height: 80rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-solidarfund.jpg);
}

.f-hero__libertarianatheneon {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-libertarianatheneon.jpg?random=323121528432525.24234);
}

.f-hero__blackredlibertatia {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-blackredlibertatia.jpg?random=3235275284423422525.24234);
}

.f-hero__antijob {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-antijob.jpg?random=32374423422525324.24234);
}

.f-hero__nytanarki {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-danskanarki.jpg?random=3235212555322411525.24234);
}

.f-hero__solidaritysix {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-solidaritysix.jpg);
}

.f-hero__s8a {
  height: 63rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-s8a.jpg);
}

.f-hero__afahiphop {
  height: 70rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-afahiphop.jpg);
}

.f-hero__solartekpak {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-solartekpak.jpg);
}

.f-hero__cuapi {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-cuapi.jpg);
}

.f-hero__cuapi_mobile {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/t-cuapi.jpg);
}

.f-hero__ragojor {
  height: 50rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-ragojor.png);
}

.f-hero__ragojor-mobile {
  height: 50rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/ragojor-mobile.png);
}

.f-hero__stophs2 {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-stophs2.jpg);
}

.f-hero__anarpatras {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-anarpatras.jpg);
}

.f-hero__solidarityrethymno {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-solidarityrethymno.jpg);
}

.f-hero__embers {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-embers.jpg);
}

.f-hero__masovka {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-masovka.jpg);
}

.f-hero__rebuildlibertatia {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-rebuildlibertatia.jpg);
}

.f-hero__rebuildlibertatia_mobile {
  height: 38rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-rebuildlibertatia2.jpg);
}

.f-hero__ssm {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-ssm.png);
}

.f-hero__cozinhasolidaria {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-cozinhasolidaria.jpg);
}

.f-hero__ssm_mobile {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-ssm_mobile.png);
}

.f-hero__akab {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-akab.jpg);
}

.f-hero__coopgreece {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-coopgreece.jpg);
}

.f-hero__vangelis {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-vangelis.jpg);
}

.f-hero__radioargayo {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/t-radioargayo.jpg);
}

.f-hero__radioargayo_mobile {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/t-radioargayo_mobile.jpg);
}

.f-hero__bumzen {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/t-bumzen.jpg);
}

.f-hero__tsc {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/t-tsc.jpg);
}

.f-hero__urutau {
  height: 43rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-urutau.jpg);
}

.f-hero__revcolombia {
  height: 50rem;
  /* is actually a png */
  background-image: url(https://firefund-assets.s3.amazonaws.com/t-revcolombia.jpg);
}

.f-hero__scafa {
  height: 60rem;
  /* is actually a png */
  background-image: url(https://firefund-assets.s3.amazonaws.com/t-scafa.jpg);
}

@media screen and (--viewport-mobile) {
  .f-hero__scafa {
    height: 66rem;
  }
}

.f-hero__100yil {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/t-100yil.jpg);
}

.f-hero__lmasn {
  height: 46rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-lmasn.jpg);
  background-color: white;
  background-size: contain;
  background-position: 50%;
}

.f-hero__lmasn_mobile {
  height: 37rem;
  /* is actually a png */
  background-image: url(https://firefund-assets.s3.amazonaws.com/t-lmasn.jpg);
  background-color: #fff;
  background-position: center;
}

.f-hero__zapatistas {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/t-zapatistas.jpg);
}

.f-hero__giannis {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-giannis.jpg);
}

.f-hero__kuofo {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-kuofo.jpg);
}

.f-hero__lbf {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-lbf.jpg);
}

.f-hero__weresistburma {
  height: 34rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-weresistburma.jpg);
}

.f-hero__forestguardians {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-forestguardians.jpg);
}

.f-hero__svendborgrodder {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-svendborgrodder.jpg);
}

.f-hero__beachdefense {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-beachdefense.jpg);
}

.f-hero__27F {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-27F.jpg);
}

.f-hero__rpsaopaolo {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-rpsaopaolo.jpg);
  background-color: black;
  background-repeat: no-repeat;
  background-size: cover;
}

.f-hero__FundLiberteOuvriere {
  height: 51rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-FundLiberteOuvriere.jpg);
}

.f-hero__ritual {
  height: 38rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-ritual.png);
}

.f-hero__editor {
  height: 60rem;
  background-image: url(../../assets/image/h-editor.jpg);
}

.f-hero__umgaeng-de,
.f-hero__umgaeng-en {
  height: 36rem;
  background-size: contain;
  background-position: center;
}

.f-hero__umgaeng-de {
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-umgaeng-de.jpg);
}

.f-hero__umgaeng-en {
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-umgaeng-en.png);
}

@media (--viewport-mobile) {
  .f-hero__umgaeng-en,
  .f-hero__umgaeng-de {
    height: 20rem;
  }
}

.f-hero__ingenillegal {
  height: 60rem;
  background-image: url(../../assets/image/h-ingenillegal.jpg);
}

.f-hero__rethymno4pairoflegs {
  height: 60rem;
  background-image: url(../../assets/image/h-rethymno4pairoflegs.jpg);
}

.f-hero__apo {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-apo.jpg);
}

.f-hero__borderofshame {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/t-borderofshame.jpg);
}

.f-hero__ingenillegal {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-ingenillegal.jpg);
}

.f-hero__westay {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-westay.jpg);
}

.f-hero__alianza {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-alianza.jpg);
}

.f-hero__ncc {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-ncc.jpg);
}

.f-hero__artemaya {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-artemaya.jpg);
}

.f-hero__unitedagainstthejunta {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-unitedagainstthejunta.jpg);
}

.f-hero__dimitris {
  height: 60rem;
  background-image: url(https://firefund-assets.s3.amazonaws.com/h-dimitris.jpg);
}

.f-hero__buchprojektkollektiv {
  height: 60rem;
  background-image: url(../../assets/image/h-buchprojektkollektiv.jpg);
}

.f-hero__fabrikayfanet {
  height: 60rem;
  background-image: url(../../assets/image/h-fabrikayfanet.jpg);
}

.f-hero__borderlesscollective {
  height: 60rem;
  background-image: url(../../assets/image/h-borderlesscollective.jpg);
}

.f-hero__machado {
  height: 60rem;
  background-image: url(../../assets/image/h-machado.jpg);
}

.f-hero__prosfygika {
  height: 60rem;
  background-image: url(../../assets/image/h-prosfygika.jpg);
}

.f-hero__sisterdorothybrigade {
  height: 60rem;
  background-image: url(../../assets/image/h-sisterdorothybrigade.jpg);
}

.f-hero__supportthesudaneseanarchists {
  height: 60rem;
  background-image: url(../../assets/image/h-supportthesudaneseanarchists.jpg);
}

.f-hero__supportthesudaneseanarchists {
  height: 60rem;
  background-image: url(../../assets/image/h-supportthesudaneseanarchists.jpg);
}

.f-hero__antifawestattica {
  height: 80rem;
  background-image: url(../../assets/image/h-antifawestattica.jpg);
}

.f-hero__supportthesudaneseanarchists {
  height: 60rem;
  background-image: url(../../assets/image/h-supportthesudaneseanarchists.jpg);
}

.f-hero__waterforland {
  height: 60rem;
  background-image: url(../../assets/image/h-waterforland.jpg);
}

.f-hero__ftpdistro {
  height: 60rem;
  background-image: url(../../assets/image/h-ftpdistro.jpg);
}

.f-hero__kplwomencollective {
  height: 60rem;
  background-image: url(../../assets/image/h-kplwomencollective.jpg);
}

.f-hero__kplwomencollective2 {
  height: 60rem;
  background-image: url(../../assets/image/h-kplwomencollective2.jpg);
}

.f-hero__messolonghisolidarity {
  height: 60rem;
  background-image: url(../../assets/image/h-messolonghisolidarity.jpg);
}

.f-hero__stockholmbookfair2022 {
  height: 60rem;
  background-image: url(../../assets/image/h-stockholmbookfair2022.jpg);
}

.f-hero__ntirhisano {
  height: 60rem;
  background-image: url(../../assets/image/h-ntirhisano.jpg);
}

.f-hero__mvap {
  height: 41.7rem;
  background-image: url(../../assets/image/h-mvap.jpg);
}

.f-hero__bookfair2022 {
  height: calc(100vw / 2.4);
  /* magic number */
  max-height: 58.7rem;
  /* from editor */
  background-image: url(../../assets/image/h-bookfair2022.jpg);
  background-position: right bottom;
}

@media (--viewport-mobile) {
  .f-hero__bookfair2022 {
    height: 26.3rem;
    /* magic number */
    background-size: cover;
  }
}

.f-hero__booksforprisoners {
  height: 62rem;
  background-image: url(../../assets/image/h-booksforprisoners.jpg);
}

@media (--viewport-mobile) {
  .f-hero__booksforprisoners {
    height: 82rem;
  }
}

.f-hero__eutopia {
  height: 60rem;
  background-image: url(../../assets/image/h-eutopia.jpg);
}

.f-hero__abcbelarus {
  height: 60rem;
  background-image: url(../../assets/image/h-abcbelarus.jpg);
}

.f-hero__childrenhousesykapro {
  height: 60rem;
  background-image: url(../../assets/image/h-childrenhousesykapro.jpg);
}

.f-hero__takeconcreteaction {
  height: 60rem;
  background-image: url(../../assets/image/h-takeconcreteaction.jpg);
}

.f-hero__vris {
  height: 60rem;
  background-image: url(../../assets/image/h-vris.jpg);
}

.f-hero__cantabriametalworkers {
  height: 60rem;
  background-image: url(../../assets/image/cantabriametalworkers2.jpg);
}

.f-hero__june30march {
  height: 60rem;
  background-image: url(../../assets/image/h-june30march.jpg);
}

.f-hero__strikefundfemern {
  height: 60rem;
  background-image: url(../../assets/image/h-strikefundfemern.jpg);
}

.f-hero__struggle4michailidis {
  height: 60rem;
  background-image: url(../../assets/image/h-struggle4michailidis.jpg);
}

.f-hero__protectthebears {
  height: 60rem;
  background-image: url(../../assets/image/h-protectthebears.jpg);
}

.f-hero__stopahunanhydropower {
  height: 60rem;
  background-image: url(../../assets/image/h-stopahunanhydropower.jpg);
}

.f-hero__struggle4eleonas {
  height: 60rem;
  background-image: url(../../assets/image/h-struggle4eleonas.jpg);
}

.f-hero__t4forctz {
  height: 60rem;
  background-image: url(../../assets/image/h-t4forctz.jpg);
}

.f-hero__eunomia {
  height: 60rem;
  background-image: url(../../assets/image/h-eunomia.jpg);
}

.f-hero__firefightersantifascistbrazil {
  height: 60rem;
  background-image: url(../../assets/image/h-firefightersantifascistbrazil.jpg);
}

.f-hero__avtonomorg2022 {
  height: 60rem;
  background-image: url(../../assets/image/h-avtonomorg2022.jpg);
}

.f-hero__palanghitam {
  height: 60rem;
  background-image: url(../../assets/image/h-palanghitam.jpg);
  background-position: bottom right 40%;
}

.f-hero__tameio22 {
  height: 60rem;
  background-image: url(../../assets/image/h-tameio22.jpg);
}

.f-hero__t4forctz161 {
  height: 60rem;
  background-image: url(../../assets/image/h-t4forctz161.jpg);
}

.f-hero__freehectorllaitul {
  height: 60rem;
  background-image: url(../../assets/image/h-freehectorllaitul.jpg);
}

.f-hero__testjon2 {
  height: 60rem;
  background-image: url(../../assets/image/h-testjon2.jpg);
}

.f-hero__checktest {
  height: 60rem;
  background-image: url(../../assets/image/h-checktest.jpg);
}

.f-hero__financialcampaigneleutheriakofestivalthessaloniki {
  height: 60rem;
  background-image: url(../../assets/image/h-financialcampaigneleutheriakofestivalthessaloniki.jpg);
}

.f-hero__test420 {
  height: 60rem;
  background-image: url(../../assets/image/h-test420.jpg);
}

.f-hero__financialcampaigneleutheriakofestivalthessaloniki {
  height: 60rem;
  background-image: url(../../assets/image/h-financialcampaigneleutheriakofestivalthessaloniki.jpg);
}

.f-hero__copwatchgreece {
  height: 60rem;
  background-image: url(../../assets/image/h-copwatchgreece.jpg);
}

.f-hero__akamzamani {
  height: 60rem;
  background-image: url(../../assets/image/h-akamzamani.jpg);
}

.f-hero__neasmirni {
  background-image: url(../../assets/image/h-neasmirni.jpg);
}

.f-hero__copwatchgreece {
  height: 60rem;
  background-image: url(../../assets/image/h-copwatchgreece.jpg);
}

.f-hero__testhappy {
  height: 60rem;
  background-image: url(../../assets/image/h-testhappy.jpg);
}

.f-hero__100testimages {
  height: 60rem;
  background-image: url(../../assets/image/h-100testimages.jpg);
}

.f-hero__testfinancialcampaigneleutheriakofestivalthessaloniki {
  height: 60rem;
  background-image: url(../../assets/image/h-testfinancialcampaigneleutheriakofestivalthessaloniki.jpg);
}

.f-hero__akamzamani {
  height: 60rem;
  background-image: url(../../assets/image/h-akamzamani.jpg);
}

.f-hero__testifdown {
  height: 60rem;
  background-image: url(../../assets/image/h-testifdown.jpg);
}

.f-hero__neasmirni {
  height: 60rem;
  background-image: url(../../assets/image/h-neasmirni.jpg);
}

.f-hero__testlongerhero {
  height: 60rem;
  background-image: url(../../assets/image/h-testlongerhero.jpg);
}

.f-hero__412 {
  height: 60rem;
  background-image: url(../../assets/image/h-412.jpg);
}

.f-hero__testonthetrain {
  height: 60rem;
  background-image: url(../../assets/image/h-testonthetrain.jpg);
}

.f-hero__schtransport {
  height: 60rem;
  background-image: url(../../assets/image/h-schtransport.jpg);
}

.f-hero__protectthewolves {
  height: 60rem;
  background-image: url(../../assets/image/h-protectthewolves.jpg);
}

.f-hero__antiwarprisoners2022 {
  height: 60rem;
  background-image: url(../../assets/image/h-antiwarprisoners2022.jpg);
}

.f-hero__amorfatispace {
  height: 60rem;
  background-image: url(../../assets/image/h-amorfatispace.jpg);
}

.f-hero__womenweavingfuture {
  background-image: url(../../assets/image/h-womenweavingfuture.jpg);
}

.f-hero__exarchiafacingextinction {
  background-image: url(../../assets/image/h-exarchiafacingextinction.jpg);
}

.f-hero__financialsupportcampaingstekiperasma {
  height: 60rem;
  background-image: url(../../assets/image/h-financialsupportcampaingstekiperasma.jpg);
}

.f-hero__financialsupportcampaingstekiperasma-EL {
  height: 60rem;
  background-image: url(../../assets/image/h-financialsupportcampaingstekiperasma-EL.jpg);
}

.f-hero__financialsupportcampaingstekiperasma-FR {
  height: 60rem;
  background-image: url(../../assets/image/h-financialsupportcampaingstekiperasma-FR.jpg);
}

.f-hero__financialsupportcampaingstekiperasma-EN {
  height: 60rem;
  background-image: url(../../assets/image/h-financialsupportcampaingstekiperasma-EN.jpg);
}

.f-hero__nbk2022 {
  height: 60rem;
  background-image: url(../../assets/image/h-nbk2022.jpg);
}

.f-hero__solidarity4comrades {
  background-image: url(../../assets/image/h-solidarity4comrades.jpg);
}

.f-hero__kwstask {
  height: 60rem;
  background-image: url(../../assets/image/h-kwstask.jpg);
}

.f-hero__solidarityantifa {
  height: 60rem;
  background-image: url(../../assets/image/h-solidarityantifa.jpg);
}

.f-hero__ncciii {
  height: 60rem;
  background-image: url(../../assets/image/h-ncciii.jpg);
}

.f-hero__internacionalistbrigade {
  height: 60rem;
  background-image: url(../../assets/image/h-internacionalistbrigade.jpg);
}

.f-hero__druckpresscollective {
  height: 60rem;
  background-image: url(../../assets/image/h-druckpresscollective.jpg);
}

.f-hero__protectthelynx {
  background-image: url(../../assets/image/h-protectthelynx.jpg);
}

.f-hero__solidaritywithpeopleonthemove {
  height: 60rem;
  background-image: url(../../assets/image/h-solidaritywithpeopleonthemove.jpg);
}

.f-hero__antifabg {
  background-image: url(../../assets/image/h-antifabg.jpg);
}

.f-hero__protectthelynx {
  background-image: url(../../assets/image/h-protectthelynx.jpg);
}

.f-hero__solidaritywithpeopleonthemove {
  height: 60rem;
  background-image: url(../../assets/image/h-solidaritywithpeopleonthemove.jpg);
}

.f-hero__antifagymgreece {
  height: 60rem;
  background-image: url(../../assets/image/h-antifagymgreece.jpg);
}

.f-hero__antifaelefsinasolidarity {
  height: 60rem;
  background-image: url(../../assets/image/h-antifaelefsinasolidarity.jpg);
}

.f-hero__elchef {
  background-image: url(../../assets/image/h-elchef.jpg);
}

.f-hero__solidariasupport {
  height: 60rem;
  background-image: url(../../assets/image/h-solidariasupport.jpg);
}

.f-hero__terra23 {
  height: 60rem;
  background-image: url(../../assets/image/h-terra23.jpg);
}

.f-hero__learningfromrojava {
  height: 60rem;
  background-image: url(../../assets/image/h-learningfromrojava.jpg);
}

.f-hero__nobordersteampoland {
  height: 60rem;
  background-image: url(../../assets/image/h-nobordersteampoland.jpg);
}

.f-hero__heyvasor {
  height: 60rem;
  background-image: url(../../assets/image/h-heyvasor.jpg);
}

.f-hero__medical23 {
  height: 60rem;
  background-image: url(../../assets/image/h-medical23.jpg);
}

.f-hero__4pretrial {
  height: 75.2rem;
  background-image: url(../../assets/image/h-4pretrial.jpg);
}

.f-hero__diktio {
  height: 60rem;
  background-image: url(../../assets/image/h-diktio.jpg);
}

.f-hero__mrgaschool {
  height: 60rem;
  background-image: url(../../assets/image/h-mrgaschool.jpg);
}

.f-hero__kourkoutablos {
  height: 40.7rem;
  background-image: url(../../assets/image/h-kourkoutablos.jpg);
}

.f-hero__freedom4comrades {
  background-image: url(../../assets/image/h-freedom4comrades.jpg);
}

.f-hero__nativelandsaopaulo {
  background-image: url(../../assets/image/h-nativelandsaopaulo.jpg);
}

.f-hero__fightforthewildlife {
  background-image: url(../../assets/image/h-fightforthewildlife.jpg);
}

.f-hero__stockholmbookfair {
  background-image: url(../../assets/image/h-stockholmbookfair.jpg);
}

.f-hero__thestateistherealterrorist {
  height: 60rem;
  background-image: url(../../assets/image/h-thestateistherealterrorist.jpg);
}

.f-hero__supportclimateactivist {
  height: 60rem;
  background-image: url(../../assets/image/h-supportclimateactivist.jpg);
}

.f-hero__supportclimateactivist_mobile {
  height: 60rem;
  background-image: url(../../assets/image/h-supportclimateactivist_mobile.jpg);
}

.f-hero__fgwmsolidarity {
  height: 60rem;
  background-image: url(../../assets/image/h-fgwmsolidarity.jpg);
}

.f-hero__abcbook {
  height: 60rem;
  background-image: url(../../assets/image/h-abcbook.jpg?new);
}

.f-hero__hrtshouldbefree {
  height: 60rem;
  background-image: url(../../assets/image/h-hrtshouldbefree.jpg);
}

.f-hero__eamaziotisroupa {
  height: 60rem;
  background-image: url(../../assets/image/h-eamaziotisroupa.jpg);
}

.f-hero__flora {
  height: 60rem;
  background-image: url(../../assets/image/h-flora.jpg);
}

.f-hero__savestrefihill {
  height: 60rem;
  background-image: url(../../assets/image/h-savestrefihill.jpg);
}

.f-hero__powerreciklaonica {
  height: 60rem;
  background-image: url(../../assets/image/h-powerreciklaonica.jpg);
}

.f-hero__unnamed {
  height: 40rem;
  background-image: url(../../assets/image/h-unnamed.jpg);
}

.f-hero__abolishspaceeye {
  height: 60rem;
  background-image: url(../../assets/image/h-abolishspaceeye.jpg);
}

.f-hero__firetruckrouvikonas {
  height: 60rem;
  background-image: url(../../assets/image/h-firetruckrouvikonas.jpg);
}

.f-hero__firetruckroubikonasfr {
  height: 60rem;
  background-image: url(../../assets/image/h-firetruckroubikonasfr.jpg);
}

.f-hero__firetruckroubikonas {
  height: 60rem;
  background-image: url(../../assets/image/h-firetruckroubikonas.jpg);
}

.f-hero__serikattahananwritings {
  height: 60rem;
  background-image: url(../../assets/image/h-serikattahananwritings.jpg);
}

.f-hero__firetruckroubikonas {
  height: 60rem;
  background-image: url(../../assets/image/h-firetruckroubikonas.jpg);
}

.f-hero__firetruckroubikonas {
  height: 60rem;
  background-image: url(../../assets/image/h-firetruckroubikonas.jpg);
}

.f-hero__serikattahananwritings {
  height: 60rem;
  background-image: url(../../assets/image/h-serikattahananwritings.jpg);
}

.f-hero__ntirhisanocommunitycentre {
  height: 60rem;
  background-image: url(../../assets/image/h-ntirhisanocommunitycentre.jpg);
}

.f-hero__zab {
  height: 60rem;
  background-image: url(../../assets/image/h-zab.jpg);
}

.f-hero__supportmedicalcosts {
  height: 80rem;
  background-image: url(../../assets/image/h-supportmedicalcosts.jpg);
}

.f-hero__worldyouthconference {
  height: 60rem;
  background-image: url(../../assets/image/h-worldyouthconference.jpg);
}

.f-hero__seagull {
  height: 80rem;
  background-image: url(../../assets/image/h-seagull.jpg);
}

.f-hero__campanhapeixesguarani {
  height: 60rem;
  background-image: url(../../assets/image/h-campanhapeixesguarani.jpg);
}

.f-hero__stopuopharassment {
  height: 60rem;
  background-image: url(../../assets/image/h-stopuopharassment.jpg);
}

.f-hero__fenipraancof2024 {
  height: 60rem;
  background-image: url(../../assets/image/h-fenipraancof2024.jpg);
}

.f-hero__evagelismos {
  height: 60rem;
  background-image: url(../../assets/image/h-evagelismos.jpg);
}

.f-hero__pustakacatut {
  height: 60rem;
  background-image: url(../../assets/image/h-pustakacatut.jpg);
}

.f-hero__tumenskoedelo {
  height: 60rem;
  background-image: url(../../assets/image/h-tumenskoedelo.jpg);
}

.f-hero__bruchstrasse64besetzt {
  height: 60rem;
  background-image: url(../../assets/image/h-bruchstrasse64besetzt.jpg);
}

.f-hero__internationalsolidarityforprosfygika {
  height: 60rem;
  background-image: url(../../assets/image/h-internationalsolidarityforprosfygika.jpg);
}

.f-hero__freedandelion {
  height: 60rem;
  background-image: url(../../assets/image/h-freedandelion.jpg);
}

.f-hero__anarchistagainstwarinrussia {
  height: 60rem;
  background-image: url(../../assets/image/h-anarchistagainstwarinrussia.jpg);
}

.f-hero__antifastrugglecy {
  height: 60rem;
  background-image: url(../../assets/image/h-antifastrugglecy.jpg);
}

.f-hero__knasterfahrung {
  height: 60rem;
  background-image: url(../../assets/image/h-knasterfahrung.jpg);
}

.f-hero__ifpalestine {
  height: 60rem;
  background-image: url(../../assets/image/h-ifpalestine.jpg);
}

.f-hero__hangalakani01 {
  height: 60rem;
  background-image: url(../../assets/image/h-hangalakani01.jpg);
}

.f-hero__akadimiaplatona {
  height: 60rem;
  background-image: url(../../assets/image/h-akadimiaplatona.jpg);
}

.f-hero__casaencantada {
  height: 60rem;
  background-image: url(../../assets/image/h-casaencantada.jpg);
}

.f-hero__banhadoresiste {
  height: 60rem;
  background-image: url(../../assets/image/h-banhadoresiste.jpg);
}

.f-hero__tca {
  height: 60rem;
  background-image: url(../../assets/image/h-tca.jpg);
}

.f-hero__lucaspacheco {
  height: 60rem;
  background-image: url(../../assets/image/h-lucaspacheco.jpg);
}

.f-hero__cuapi2 {
  height: 60rem;
  background-image: url(../../assets/image/h-cuapi2.jpg);
}

.f-hero__worktifarm {
  height: 60rem;
  background-image: url(../../assets/image/h-worktifarm.jpg);
}

.f-hero__damakoupa {
  height: 60rem;
  background-image: url(../../assets/image/h-damakoupa.jpg);
}

.f-hero__bigmamas {
  height: 60rem;
  background-image: url(../../assets/image/h-bigmamas.jpg);
}

.f-hero__pushbackborders {
  height: 60rem;
  background-image: url(../../assets/image/h-pushbackborders.jpg);
}

.f-hero__blitz {
  height: 60rem;
  background-image: url(../../assets/image/h-blitz.jpg);
}

.f-hero__supportfootballtrophika {
  height: 60rem;
  background-image: url(../../assets/image/h-supportfootballtrophika.jpg);
}

.f-hero__capitalistfire {
  height: 60rem;
  background-image: url(../../assets/image/h-capitalistfire.jpg);
}

.f-hero__blitz {
  height: 60rem;
  background-image: url(../../assets/image/h-blitz.jpg);
}

.f-hero__supportfootballtrophika {
  height: 60rem;
  background-image: url(../../assets/image/h-supportfootballtrophika.jpg);
}

.f-hero__antarvegan {
  height: 60rem;
  background-image: url(../../assets/image/h-antarvegan.jpg);
}

.f-hero__mia {
  height: 60rem;
  background-image: url(../../assets/image/h-mia.jpg);
}

.f-hero__helpafamily {
  height: 60rem;
  background-image: url(../../assets/image/h-helpafamily.jpg);
}

.f-hero__patrasolidarity {
  height: 60rem;
  background-image: url(../../assets/image/h-patrasolidarity.jpg);
}

.f-hero__kenan {
  height: 60rem;
  background-image: url(../../assets/image/h-kenan.jpg);
}

.f-hero__kenan_mobile {
  height: 60rem;
  background-image: url(../../assets/image/h-kenan_mobile.jpg);
}

.f-hero__protectmenandboys {
  height: 60rem;
  background-image: url(../../assets/image/h-protectmenandboys.jpg);
}

.f-hero__initiativeagainstforcedstarvation {
  height: 60rem;
  background-image: url(../../assets/image/h-initiativeagainstforcedstarvation.jpg);
}

.f-hero__accionantifabuenosaires {
  height: 60rem;
  background-image: url(../../assets/image/h-accionantifabuenosaires.jpg);
}

.f-hero__cuttheties {
  height: 60rem;
  background-image: url(../../assets/image/h-cuttheties.jpg);
}

.f-hero__cuttheties_mobile {
  height: 60rem;
  background-image: url(../../assets/image/h-cuttheties_mobile.png);
}

.f-hero__testingtinycampaign5 {   height: 60rem;  background-image: url(../../assets/image/h-testingtinycampaign5.jpg); }
.f-hero__testingagain10 {   height: 60rem;  background-image: url(../../assets/image/h-testingagain10.jpg); }
.f-hero__testcontent2 {   height: 60rem;  background-image: url(../../assets/image/h-testcontent2.jpg); }
.f-hero__testmobile {   height: 60rem;  background-image: url(../../assets/image/h-testmobile.jpg); }
.f-hero__testingtranslationflow {   height: 60rem;  background-image: url(../../assets/image/h-testingtranslationflow.jpg); }
.f-hero__testingtranslationflow2 {   height: 60rem;  background-image: url(../../assets/image/h-testingtranslationflow2.jpg); }
.f-hero__testingtranslationflow5 {   height: 60rem;  background-image: url(../../assets/image/h-testingtranslationflow5.jpg); }
.f-hero__testingtranslationflow6 {   height: 60rem;  background-image: url(../../assets/image/h-testingtranslationflow6.jpg); }
.f-hero__testingtranslationflow7 {   height: 60rem;  background-image: url(../../assets/image/h-testingtranslationflow7.jpg); }
.f-hero__testingtranslationflow3 {   height: 60rem;  background-image: url(../../assets/image/h-testingtranslationflow3.jpg); }
.f-hero__testingtranslationflow8 {   height: 60rem;  background-image: url(../../assets/image/h-testingtranslationflow8.jpg); }
.f-hero__testingtranslationflow9 {   height: 60rem;  background-image: url(../../assets/image/h-testingtranslationflow9.jpg); }
.f-hero__testingtranslationflow10 {   height: 60rem;  background-image: url(../../assets/image/h-testingtranslationflow10.jpg); }
.f-hero__testingtranslationflow11 {   height: 60rem;  background-image: url(../../assets/image/h-testingtranslationflow11.jpg); }
.f-hero__testingtranslationflow12 {   height: 60rem;  background-image: url(../../assets/image/h-testingtranslationflow12.jpg); }
.f-hero__testingtranslationflow13 {   height: 60rem;  background-image: url(../../assets/image/h-testingtranslationflow13.jpg); }
.f-hero__electraapostolou {   height: 60rem;  background-image: url(../../assets/image/h-electraapostolou.jpg); }
.f-hero__electraapostolou {
  height: 60rem;
  background-image: url(../../assets/image/h-electraapostolou.jpg);
}

.f-hero__testingnewserver {   height: 60rem;  background-image: url(../../assets/image/h-testingnewserver.jpg); }



/* MOST RECENT HERO'S / NEW CAMPAIGNS new campaigns GO ABOVE THIS LINE*/

/* `<img>` tags are not hidden */

.f-hero img {
  display: block;
}

.f-hero__videomask {
  /*width: 350px;*/
  border-radius: 10px;
  overflow: hidden;
}

.f-hero__wrapper {
  lost-center: var(--page-max-width);
}

.f-hero__wrapper_video {
  height: 0;
}

.f-hero__wrapper_big {
  padding-top: 11%;
}

.f-hero__wrapper_bigdata {
  padding-top: 0%;
}

.f-hero__heading {
  position: relative;
  /* this will look bad but won't break the mobile layout */
  overflow-x: auto;
  /* add scroll bar if heading is too wide to fit page */
}

/* OLD AND NOT USED ANYMORE
.f-hero__heading::before {
	display: none;

	position: absolute;
	top: 0;
	left: -50.8rem;
	width: 50rem;
	height: 100%;

	background-color: var(--color-black);

	content: "";
} */

.f-hero__heading h1 {
  display: inline;
  margin: 3rem 0 0 0;
  padding-right: 1.4rem;
  padding-left: 0.8rem;
  background-color: rgba(0, 0, 0, 0.65);
  color: var(--color-white);
  font-size: 7.7rem;
  font-weight: lighter;
}

.f-hero__heading h1:first-child {
  margin-left: 2.6rem;
}

.f-hero__heading-2 {
  margin: 0 0 0 1.6em;
  /* because of our font char padding, numbers do not add up with f-hero__text */
  padding-left: 0.3rem;
  /* this will look bad but won't break the mobile layout */
  overflow-x: auto;
  /* add scroll bar if heading is too wide to fit page */
}

.f-hero__heading-2[dir="rtl"] {
  margin: 0 3.4rem 0 0;
  padding-right: 0.8rem;
}

.f-hero__heading-2 h1 {
  display: inline;
  margin: 0;
  /* see https://css-tricks.com/multi-line-padded-text/
		for how to get padding to follow word-breaks (box-shadow)
	We want the following padding on each line
	padding-right: 0.8rem;
	padding-left: 0.8rem;
	*/
  padding: 0;
  box-shadow: 0.8rem 0 0 var(--color-black-transparent), -0.3rem 0 0 var(--color-black-transparent);
  box-decoration-break: clone;
  background-color: var(--color-black-transparent, rgba(0, 0, 0, 0.65));
  color: var(--color-white);
  font-size: 7.7rem;
  font-weight: lighter;
}

@media screen and (--viewport-mobile) {
  .f-hero__text[dir="rtl"] {
    margin: 0.16rem 3.4rem 0 0;
  }
}

.f-hero__heading_campaign h1:first-child {
  margin-left: 0rem;
  padding-left: 0.8rem;
  position: relative;
  left: 2.6rem;
}

.f-hero__heading_trans h1 {
  background-color: transparent;
}

.f-hero__heading_swarm h1 {
  padding: 0.4rem 1.9rem 0rem 2.1rem;
}

.f-hero__heading_mobile h1 {
  display: inline-block;
  margin-top: 1.4rem;
  margin-bottom: 1rem;
  padding: 1.4rem;
  background-color: var(--color-black-transparent);
  color: var(--color-white);
  font-size: 4.5rem;
  font-weight: lighter;
}

.f-hero__heading_mobile h1:first-child {
  margin-left: 2.6rem;
}

.f-hero__heading_mobile h1:nth-child(2) {
  margin-left: 0;
  padding-left: 0;
}

.f-hero__heading_link a {
  color: var(--color-white);
}

.f-hero__text_splash {
  display: inline;
  font-size: 2.5rem;
  color: var(--color-white);
  font-weight: lighter;
}

.f-hero__text {
  position: relative;
  margin: 0.16rem 0 0 3.4rem;
  z-index: 1;
}

.f-hero__text span,
.f-hero__text > * {
  margin-left: -0.8rem;
  padding: 0.8rem;
  background-color: var(--color-black-transparent);
  color: var(--color-white);
  font-size: 1.8rem;
  font-weight: lighter;
  line-height: 4.1rem;
  display: inline;
  box-decoration-break: clone;
}

/*
## TODO: Needs to be done in js
If the window is below or equal the image width then `background-size: cover;`.

E.I. the sides are cut.

If thw window is above the image width, then `background-size: auto;`

E.I. the top of the image is cut.

The image image width should be measured from the actual image via js.
*/

@media screen and (min-width: 1350px) {

}

.ql-toolbar.ql-snow {
  border: none !important;
}

#hero > div.ql-editor > h1 {
  margin: 0 0 0 1.6em !important;
  /* because of our font char padding, numbers do not add up with f-hero__text */
  padding-left: 0.3rem !important;
  /* this will look bad but won't break the mobile layout */
  overflow-x: auto !important;
  display: inline !important;
  margin: 0 !important;
  padding: 0 !important;
  box-shadow: 0.8rem 0 0 var(--color-black-transparent), -0.3rem 0 0 var(--color-black-transparent) !important;
  box-decoration-break: clone !important;
  background-color: var(--color-black-transparent, rgba(0, 0, 0, 0.65)) !important;
  color: var(--color-white) !important;
  font-size: 7.7rem !important;
  font-weight: lighter !important;
}

#hero {
  padding-top: 0 !important;
}

#hero > div.ql-editor > p {
  display: table !important;
  margin: 0.26rem 0 0 -0.3rem !important;
  position: relative !important;
  margin-left: -0.4rem !important;
  padding: 0rem 0.8rem 0rem 0.8rem !important;
  /*padding: 0.2rem .72rem 3.4rem !important;*/
  /*#TODO:120 make the 3.4 a global variable */
  background-color: var(--color-black-transparent) !important;
  color: var(--color-white) !important;
  font-size: 1.8rem !important;
  font-weight: lighter !important;
  line-height: 4.1rem !important;
  /*white-space: nowrap !important;*/
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border: none !important;
}

.ql-toolbar.ql-snow {
  /*position: absolute;*/
}